import { Heading, Flex, Text, Container } from '@chakra-ui/react';

import SeoHead from '~/components/common/Head';
import { GlobalLayout } from '~/layouts/GlobalLayout';
import { usePageError, useI18nLn } from '~/hooks/store';

export default function Custom404() {
  const { page_error, setPageError } = usePageError();
  const { t } = useI18nLn();

  return (
    <GlobalLayout
      error={page_error}
      is_show_error={page_error !== null}
      closeError={() => setPageError(null)}
    >
      <SeoHead
        title="404エラー"
        description="指定されたURLのページが存在しません"
        url="/404"
      />
      <Container flexDirection="column" size="container.lg" textAlign="center">
        <Flex flexDir="column">
          <Heading fontSize="4xl" p={6} textAlign="center">
            404
          </Heading>
          <Text p={4} fontSize="lg">
            {t('errors.404.title')}
          </Text>
          <Text p={4}>{t('errors.404.description')}</Text>
        </Flex>
      </Container>
    </GlobalLayout>
  );
}
